/**
 * Shop reducer
 * @module reducers/shop/article_categories
 */

import { GET_ARTICLE_CATEGORIES } from '../../constants/ActionTypes';

const initialState = {
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  article_categories: [],
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Shop reduceer
 * @function shop
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 * @returns {Object} New state
 */
export default function article_categories(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ARTICLE_CATEGORIES}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_ARTICLE_CATEGORIES}_SUCCESS`:
      const { closed_dates, no_delivery_dates } = action.result;
      return {
        ...state,
        article_categories: action.result.categories,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_ARTICLE_CATEGORIES}_FAIL`:
      return {
        ...state,
        article_categories: [],
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
