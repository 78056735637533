import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Select } from 'semantic-ui-react';
import { orderBy } from 'lodash';

const messages = defineMessages({
  all: {
    id: 'All categories',
    defaultMessage: 'All categories',
  },
  categories: {
    id: 'Product Category',
    defaultMessage: 'Product Category',
  },
});

const ArticleCategoriesFilter = ({ categories, selectCategory }) => {
  const intl = useIntl();

  const categoriesOptions = [
    { key: '_all', value: null, text: intl.formatMessage(messages.all) },
    ...orderBy(
      categories.map((category) => ({
        key: category.id,
        value: category.id,
        text: category.name,
      })),
      [(c) => c.text],
      ['asc'],
    ),
  ];

  if (categories) {
    return (
      <Select
        placeholder={intl.formatMessage(messages.categories)}
        options={categoriesOptions}
        onChange={(event, { value }) => selectCategory(value)}
      />
    );
  } else {
    return null;
  }
};

export default ArticleCategoriesFilter;
