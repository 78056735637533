/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
export const ADD_SHOP_CLOSED_DATE = 'ADD_SHOP_CLOSED_DATE';
export const DELETE_SHOP_CLOSED_DATE = 'DELETE_SHOP_CLOSED_DATE';
export const LIST_SHOP_CLOSED_DATES = 'LIST_SHOP_CLOSED_DATES';

export const GET_ARTICLE_CATEGORIES = 'GET_ARTICLE_CATEGORIES';

export const POST_DEACTIVATE_ARTICLE = 'POST_DEACTIVATE_ARTICLE';
export const POST_ACTIVATE_ARTICLE = 'POST_ACTIVATE_ARTICLE';
export const PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE =
  'PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE';

export const GET_MY_SHOPS = 'GET_MY_SHOPS';
