/**
 * CurrencyWidget component.
 * @module components/manage/Widgets/CurrencyWidget
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';
import { injectIntl } from 'react-intl';
import { localeParseFloat } from '../../../helpers';

/**
 * CurrencyWidget component
 * */
const CurrencyWidget = (props) => {
  const {
    id,
    value,
    onChange,
    onBlur,
    onClick,
    defaultValue,
    isDisabled,
    placeholder,
  } = props;

  function formatValue(value) {
    return value
      ? parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '';
  }

  const [state, setState] = useState(() => ({
    myNumber: value || defaultValue,
    formattedNumber: formatValue(value || defaultValue),
    isValid: true,
  }));

  function validateNumberField(myNumber) {
    const numberRegEx = /-?\d*[.,]?\d{1,2}/;
    return numberRegEx.test(String(myNumber).toLowerCase());
  }

  function onChange2(id, value) {
    const isValid = !value || validateNumberField(value);
    setState({
      myNumber: localeParseFloat(value),
      formattedNumber: value,
      isValid,
    });
    if (isValid) {
      onChange(id, value);
    }
  }

  function onBlur2(id, value) {
    console.log('onBlur2', state.myNumber, formatValue(state.myNumber));
    setState({
      ...state,
      formattedNumber: formatValue(state.myNumber),
    });
    if (state.isValid && onBlur) {
      onBlur(id, state.myNumber);
    }
  }

  return (
    <FormFieldWrapper {...props}>
      <Input
        id={`field-${id}`}
        name={id}
        type="text"
        disabled={isDisabled}
        value={state.formattedNumber}
        placeholder={placeholder}
        onChange={({ target }) =>
          onChange2(id, target.value === '' ? undefined : target.value)
        }
        onBlur={({ target }) =>
          onBlur2(id, target.value === '' ? undefined : target.value)
        }
        onClick={() => onClick()}
      />
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
CurrencyWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  wrapped: PropTypes.bool,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
CurrencyWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
};

export default injectIntl(CurrencyWidget);
