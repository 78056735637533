import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

import { cloneDeep } from 'lodash';

const messages = defineMessages({
  add: {
    id: 'Add option',
    defaultMessage: 'Add option',
  },
  addGroup: {
    id: 'Add group option',
    defaultMessage: 'Add group option',
  },
  groupDescription: {
    id: 'Group Description',
    defaultMessage: 'Description',
  },
  groupMaxDifferentOptions: {
    id: 'Group Maximum Different Options',
    defaultMessage: 'Maximum Different Options',
  },
  groupMaxDifferentOptionsDesc: {
    id: 'Group Maximum Different Options Desc',
    defaultMessage:
      "Maximum different options the user can select from this group option, if 0 or empty then it's unlimited.",
  },
  groupMaxTotalQuantity: {
    id: 'Group Maximum Total Quantity',
    defaultMessage: 'Maximum Total Quantity',
  },
  groupMaxTotalQuantityDesc: {
    id: 'Group Maximum Total Quantity Desc',
    defaultMessage:
      "Maximum quantity of options the user can select from this group option, if 0 or empty then it's unlimited.",
  },
  groupMinDifferentOptions: {
    id: 'Group Minimum Different Options',
    defaultMessage: 'Minimum different Options',
  },
  groupMinDifferentOptionsDesc: {
    id: 'Group Minimum Different Options Desc',
    defaultMessage:
      'Minimum different options the user can select from this group option.',
  },
  groupMinTotalQuantity: {
    id: 'Group Minimum Total Quantity',
    defaultMessage: 'Minimum Total Quantity',
  },
  groupMinTotalQuantityDesc: {
    id: 'Group Minimum Total Quantity Desc',
    defaultMessage:
      'Minimum quantity of options the user can select from this group option, if empty then 0.',
  },
  groupName: {
    id: 'Group Name',
    defaultMessage: 'Name',
  },
  groupOptionList: {
    id: 'Option list',
    defaultMessage: 'Option list',
  },
  groupSubstitutesBasePrice: {
    id: 'Substitutes Base Price',
    defaultMessage: 'Substitutes Base Price',
  },
  groupSubstitutesBasePriceDesc: {
    id: 'Substitutes Base Price Description',
    defaultMessage:
      // eslint-disable-next-line no-multi-str
      'By activating this option, you indicate that this is the main group options \
and the price of its options will substitute the base price of the product. \
The options inside this group must all have a price.',
  },
  optionDefaultOption: {
    id: 'Default Option',
    defaultMessage: 'Default Option',
  },
  optionDefaultOptionDesc: {
    id: 'Default Option Desc',
    defaultMessage:
      'Select only one option as the default option inside this group option.',
  },
  optionDescription: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  optionMaximumQuantity: {
    id: 'Option Maximum Quantity',
    defaultMessage: 'Maximum Quantity',
  },
  optionMaximumQuantityDesc: {
    id: 'Option Maximum Quantity Desc',
    defaultMessage:
      "Maximum number of times the user can select this options, if 0 or empty then it's unlimited.",
  },
  optionName: {
    id: 'Option Name',
    defaultMessage: 'Name',
  },
  optionPrice: {
    id: 'Option Price',
    defaultMessage: 'Price',
  },
});

const groupOptionSchemaExtender = (schema, data, intl) => {
  const mutated = cloneDeep(schema);
  mutated.title = data.name;
  return mutated;
};

const optionSchemaExtender = (schema, data, intl) => {
  const mutated = cloneDeep(schema);
  mutated.title = data.name;
  //if (!data.maxQuantity) {
  //  data.maxQuantity = 1;
  //}
  return mutated;
};

const optionSchema = (intl) => ({
  title: 'Option',
  addMessage: intl.formatMessage(messages.add),
  properties: {
    name: {
      title: intl.formatMessage(messages.optionName),
    },
    description: {
      title: intl.formatMessage(messages.optionDescription),
      widget: 'textarea',
    },
    defaultOption: {
      title: intl.formatMessage(messages.optionDefaultOption),
      description: intl.formatMessage(messages.optionDefaultOptionDesc),
      type: 'boolean',
    },
    price: {
      title: intl.formatMessage(messages.optionPrice),
      type: 'number',
    },
    maxQuantity: {
      title: intl.formatMessage(messages.optionMaximumQuantity),
      description: intl.formatMessage(messages.optionMaximumQuantityDesc),
      type: 'integer',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['name', 'description', 'defaultOption', 'price', 'maxQuantity'],
    },
  ],
  required: ['name', 'maxQuantity'],
});

const groupOptionsSchema = (intl) => ({
  title: 'Group Options',
  addMessage: intl.formatMessage(messages.addGroup),
  properties: {
    name: {
      title: intl.formatMessage(messages.groupName),
    },
    description: {
      title: intl.formatMessage(messages.groupDescription),
      widget: 'textarea',
    },
    minDifferentOptions: {
      title: intl.formatMessage(messages.groupMinDifferentOptions),
      description: intl.formatMessage(messages.groupMinDifferentOptionsDesc),
      type: 'integer',
      minimum: 0,
    },
    maxDifferentOptions: {
      title: intl.formatMessage(messages.groupMaxDifferentOptions),
      description: intl.formatMessage(messages.groupMaxDifferentOptionsDesc),
      type: 'integer',
      minimum: 0,
    },
    minTotalQuantity: {
      title: intl.formatMessage(messages.groupMinTotalQuantity),
      description: intl.formatMessage(messages.groupMinTotalQuantityDesc),
      type: 'integer',
      minimum: 0,
    },
    maxTotalQuantity: {
      title: intl.formatMessage(messages.groupMaxTotalQuantity),
      description: intl.formatMessage(messages.groupMaxTotalQuantityDesc),
      type: 'integer',
      minimum: 0,
    },
    substitutesBasePrice: {
      title: intl.formatMessage(messages.groupSubstitutesBasePrice),
      description: intl.formatMessage(messages.groupSubstitutesBasePriceDesc),
      type: 'boolean',
    },
    options: {
      title: intl.formatMessage(messages.groupOptionList),
      widget: 'options_widget',
      schema: optionSchema(intl),
      schemaExtender: optionSchemaExtender,
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'name',
        'description',
        'minDifferentOptions',
        'maxDifferentOptions',
        'minTotalQuantity',
        'maxTotalQuantity',
        'substitutesBasePrice',
        'options',
      ],
    },
    // {
    //   id: 'list',
    //   title: 'List',
    //   fields: ['options'],
    // },
  ],
  required: [],
});

const OptionsWidget = (props) => {
  const intl = useIntl();

  return (
    <ObjectListWidget
      schema={groupOptionsSchema(intl)}
      schemaExtender={groupOptionSchemaExtender}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default OptionsWidget;
