/**
 * ShopView view component.
 * @module components/theme/View/ShopView
 */
import React, { useState } from 'react';
import './ShopView.css';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container, Image, Button, List } from 'semantic-ui-react';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';
import { getVocabulary } from '@plone/volto/actions';
import ShopClosedDatesCalendar from './ShopClosedDatesCalendar';
import Articles from './Articles';
import { Timetable } from '../..';
import { ClosedDates } from '../..';
import Prices from './Prices';
import useShopstaff from '../../../hooks/shopstaff';
import { Plug } from '@plone/volto/components/manage/Pluggable';

const vatRateVocabulary = 'apanymantel.cart.shopping.vat_rates';
const messages = defineMessages({
  otherChanges: {
    id: 'Make other changes',
    defaultMessage:
      'Should there be other configurations you need to do, but you can not do them through this application, please contact Apanymantel as usual or send an e-mail to info@apanymantel.com.',
  },
  calendar: {
    id: 'calendar',
    defaultMessage: 'Calendar',
  },
  articles: {
    id: 'articles',
    defaultMessage: 'Articles',
  },
  back: {
    id: 'back',
    defaultMessage: 'Back',
  },
  prices: {
    id: 'prices',
    defaultMessage: 'Prices',
  },
  shop: {
    id: 'shop',
    defaultMessage: 'Shop',
  },
});
/**
 * ShopView view component class.
 * @function ShopView
 * @params {object} content Content object.
 * @return {string} Markup of the component .
 */
const ShopView = (props) => {
  const {
    data = {},
    content,
    location: { pathname },
  } = props;

  const [state, setState] = useState(0);

  const myShops = useSelector((state) => state.my_shops.my_shops || []);
  const contentId = useSelector((state) =>
    flattenToAppURL(state.content.data['@id']),
  );
  const isShopstaff =
    myShops.filter((myShop) => myShop['@id'] === contentId).length > 0;
  // const dispatch = useDispatch();
  // const isShopstaff = useSelector((state) => {
  //   const userId = state.userSession.token
  //     ? jwtDecode(state.userSession.token).sub
  //     : null;
  //   const role = state.sharing.data.entries.filter(
  //     (entry) => userId && entry.id === userId && entry.roles.ShopstaffMember,
  //   );
  //   return role.length > 0;
  // });
  // React.useEffect(() => {
  //   dispatch(getSharing(pathname));
  // }, [dispatch, pathname]);
  // const isShopstaff = useShopstaff(pathname);

  return (
    <Container className="view-wrapper">
      {state === 0 && (
        <Plug pluggable="main.toolbar.bottom">
          <Button onClick={() => setState(3)}>
            <FormattedMessage id="prices" defaultMessage="Prices" />
          </Button>
        </Plug>
      )}
      <Helmet title={content.title} />
      <article id="content">
        <header>
          <h1 className="documentFirstHeading">{content.title}</h1>
        </header>
        {!isShopstaff && (
          <>
            {state === 0 && (
              <section id="content-core">
                {content.description && (
                  <p className="documentDescription">{content.description}</p>
                )}
                {content.logo && (
                  <Image
                    className="document-image"
                    src={content.logo.scales.thumb.download}
                    floated="right"
                  />
                )}
                {content.shop_picture && (
                  <Image
                    className="document-image"
                    src={content.shop_picture.scales.thumb.download}
                    floated="right"
                  />
                )}
                {content.summary && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.summary.data,
                    }}
                  />
                )}
                {content.directions_for_use && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.directions_for_use.data,
                    }}
                  />
                )}
                {content.specialities && (
                  <p className="documentDescription">{content.specialities}</p>
                )}
                {content.article_categories && (
                  <List>
                    {content.article_categories.map((cat) => (
                      <List.Item key={cat}>{cat}</List.Item>
                    ))}
                  </List>
                )}
                {content.minimum_ratings_to_show && (
                  <p className="documentDescription">
                    {content.minimum_ratings_to_show}
                  </p>
                )}
                {content.corporate_name && (
                  <p className="documentDescription">
                    {content.corporate_name}
                  </p>
                )}
                {content.bank_account && (
                  <p className="documentDescription">{content.bank_account}</p>
                )}
                {content.bank_bic_code && (
                  <p className="documentDescription">{content.bank_bic_code}</p>
                )}
                {content.year_founded && (
                  <p className="documentDescription">{content.year_founded}</p>
                )}
                {content.web_site && (
                  <p className="documentDescription">{content.web_site}</p>
                )}
                {content.belongs_to_store_chain && (
                  <p className="documentDescription">
                    {content.belongs_to_store_chain}
                  </p>
                )}
                {content.intermediary_fee && (
                  <p className="documentDescription">
                    {content.intermediary_fee}
                  </p>
                )}
                {content.intermediary_fee_pickup && (
                  <p className="documentDescription">
                    {content.intermediary_fee_pickup}
                  </p>
                )}
                {content.exclude_shipping_costs_from_fee && (
                  <p className="documentDescription">
                    {content.exclude_shipping_costs_from_fee}
                  </p>
                )}
                {content.vat_reg_number && (
                  <p className="documentDescription">
                    {content.vat_reg_number}
                  </p>
                )}
                {/* {content.vat_rate && ( */}
                {/*   <p className="documentDescription">{content.vat_rate}</p> */}
                {/* )} */}
                {content.address && (
                  <p className="documentDescription">{content.address}</p>
                )}
                {content.post_code && (
                  <p className="documentDescription">{content.post_code}</p>
                )}
                {content.city && (
                  <p className="documentDescription">{content.city}</p>
                )}
                {content.county && (
                  <p className="documentDescription">{content.county}</p>
                )}
                {content.country && (
                  <p className="documentDescription">{content.country}</p>
                )}
                {/* {content.timezone && ( */}
                {/*   <p className="documentDescription">{content.timezone}</p> */}
                {/* )} */}
                {content.phone_no && (
                  <p className="documentDescription">{content.phone_no}</p>
                )}
                {content.email && (
                  <p className="documentDescription">{content.email}</p>
                )}
                {content.invoicing_emails && (
                  <List>
                    {content.invoicing_emails.map((email) => (
                      <List.Item key={email}>{email}</List.Item>
                    ))}
                  </List>
                )}

                {/* Owner/partner data */}
                {content.owner_firstname && (
                  <p className="documentDescription">
                    {content.owner_firstname}
                  </p>
                )}
                {content.owner_lastname && (
                  <p className="documentDescription">
                    {content.owner_lastname}
                  </p>
                )}
                {content.owner_lastname2 && (
                  <p className="documentDescription">
                    {content.owner_lastname2}
                  </p>
                )}
                {content.id_number && (
                  <p className="documentDescription">{content.id_number}</p>
                )}
                {content.contact_firstname && (
                  <p className="documentDescription">
                    {content.contact_firstname}
                  </p>
                )}
                {content.contact_lastname && (
                  <p className="documentDescription">
                    {content.contact_lastname}
                  </p>
                )}
                {content.contact_lastname2 && (
                  <p className="documentDescription">
                    {content.contact_lastname2}
                  </p>
                )}
                {content.contact_mobile_no && (
                  <p className="documentDescription">
                    {content.contact_mobile_no}
                  </p>
                )}

                {/* Product Images, */}
                {content.image && (
                  <Image
                    className="document-image"
                    src={content.image.scales.thumb.download}
                    floated="right"
                  />
                )}
                {/* {content.main_city_of_shop && ( */}
                {/*   <p className="documentDescription">{content.main_city_of_shop}</p> */}
                {/* )} */}

                {/* {/\* Ordering *\/} */}
                {content.ordering_phone_no && (
                  <p className="documentDescription">
                    {content.ordering_phone_no}
                  </p>
                )}
                {content.ordering_phone_no2 && (
                  <p className="documentDescription">
                    {content.ordering_phone_no2}
                  </p>
                )}
                {content.ordering_email && (
                  <p className="documentDescription">
                    {content.ordering_email}
                  </p>
                )}
                {content.max_orders_day && (
                  <p className="documentDescription">
                    {content.max_orders_day}
                  </p>
                )}
                {content.immediate_preparing_time && (
                  <p className="documentDescription">
                    {content.immediate_preparing_time}
                  </p>
                )}
                {content.same_day_preparing_time && (
                  <p className="documentDescription">
                    {content.same_day_preparing_time}
                  </p>
                )}
                {content.minimum_amount_delivery_order && (
                  <p className="documentDescription">
                    {content.minimum_amount_delivery_order}
                  </p>
                )}
                {content.minimum_order_without_shipping_costs && (
                  <p className="documentDescription">
                    {content.minimum_order_without_shipping_costs}
                  </p>
                )}
                {content.minimum_amount_without_shipping_costs && (
                  <p className="documentDescription">
                    {content.minimum_amount_without_shipping_costs}
                  </p>
                )}
                {content.minimum_amount_pickup_order && (
                  <p className="documentDescription">
                    {content.minimum_amount_pickup_order}
                  </p>
                )}
                {content.no_product_customization && (
                  <p className="documentDescription">
                    {content.no_product_customization}
                  </p>
                )}

                {/* Trade/Delivery Calendar */}
                {content.opening_timetable && (
                  <Timetable timetable={content.opening_timetable} />
                )}

                {content.closed_dates && (
                  <ClosedDates closedDates={content.closed_dates} />
                )}
                {content.delivery_timetable && (
                  <Timetable timetable={content.delivery_timetable} />
                )}
                {content.delivery_time_interval && (
                  <p className="documentDescription">
                    {content.delivery_time_interval}
                  </p>
                )}
                {content.no_delivery_dates && (
                  <ClosedDates closedDates={content.no_delivery_dates} />
                )}
                {content.limit_time_same_day_order && (
                  <p className="documentDescription">
                    {content.limit_time_same_day_order}
                  </p>
                )}
                {content.time_for_urgent_phone_calls && (
                  <p className="documentDescription">
                    {content.time_for_urgent_phone_calls}
                  </p>
                )}
                {content.ordering_timetable && (
                  <Timetable
                    timetable={content.ordering_timetable}
                    orderTo={true}
                  />
                )}

                {/* Warning Message */}
                {content.warning_message && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.warning_message.data,
                    }}
                  />
                )}
                {content.warning_from_date && (
                  <p className="documentDescription">
                    {content.warning_from_date}
                  </p>
                )}
                {content.warning_to_date && (
                  <p className="documentDescription">
                    {content.warning_to_date}
                  </p>
                )}
              </section>
            )}
            {state === 3 && (
              <>
                <Plug pluggable="main.toolbar.bottom">
                  <Button onClick={() => setState(0)}>
                    <FormattedMessage id="shop" defaultMessage="Shop" />
                  </Button>
                </Plug>
                <Prices data={data} pathname={pathname} />
              </>
            )}
          </>
        )}
        {isShopstaff && (
          <section id="content-core">
            {state === 0 && (
              <div className="buttons-wrapper">
                <div>
                  <Button className="ui big orange" onClick={() => setState(1)}>
                    <FormattedMessage id="calendar" defaultMessage="Calendar" />
                  </Button>
                </div>
                <div>
                  <Button className="ui big orange" onClick={() => setState(2)}>
                    <FormattedMessage id="articles" defaultMessage="Articles" />
                  </Button>
                </div>
                <div>
                  <Button className="ui big orange" onClick={() => setState(3)}>
                    <FormattedMessage id="prices" defaultMessage="Prices" />
                  </Button>
                </div>
                <p>
                  <FormattedMessage
                    id="Make other changes"
                    defaultMessage="Should there be other configurations you need to do, but you can not do them through this application, please contact Apanymantel as usual or send an e-mail to info@apanymantel.com."
                  />
                </p>
              </div>
            )}
            {state === 1 && (
              <div>
                <Button
                  className="ui left labeled icon button large orange"
                  onClick={() => setState(0)}
                >
                  <i className="left arrow icon"></i>
                  <FormattedMessage id="back" defaultMessage="Back" />
                </Button>
                <ShopClosedDatesCalendar pathname={pathname} monthsQty={18} />
              </div>
            )}
            {state === 2 && (
              <div>
                <Button
                  className="ui left labeled icon button large orange"
                  onClick={() => setState(0)}
                >
                  <i className="left arrow icon"></i>
                  <FormattedMessage id="back" defaultMessage="Back" />
                </Button>
                <Articles data={data} pathname={pathname} />
              </div>
            )}
            {state === 3 && (
              <div>
                <Button
                  className="ui left labeled icon button large orange"
                  onClick={() => setState(0)}
                >
                  <i className="left arrow icon"></i>
                  <FormattedMessage id="back" defaultMessage="Back" />
                </Button>
                <Prices data={data} pathname={pathname} />
              </div>
            )}
          </section>
        )}
      </article>
    </Container>
  );
};

/**
 * Property Types
 * @property {Object} propTypes Property types.
 * @static
 */
ShopView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default ShopView;
