/**
 * Shop reducer
 * @module reducers/shop/shop
 */

import {
  ADD_SHOP_CLOSED_DATE,
  DELETE_SHOP_CLOSED_DATE,
  LIST_SHOP_CLOSED_DATES,
} from '../../constants/ActionTypes';
import moment from 'moment';

const initialState = {
  add: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  list: {
    loaded: false,
    loading: false,
    error: null,
  },
  closed_dates: {
    fixed: [],
    variable: [],
  },
  no_delivery_dates: {
    fixed: [],
    variable: [],
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Shop reduceer
 * @function shop
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 * @returns {Object} New state
 */
export default function closed_dates(state = initialState, action = {}) {
  switch (action.type) {
    case `${ADD_SHOP_CLOSED_DATE}_PENDING`:
    case `${DELETE_SHOP_CLOSED_DATE}_PENDING`:
    case `${LIST_SHOP_CLOSED_DATES}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${ADD_SHOP_CLOSED_DATE}_SUCCESS`:
      return {
        ...state,
        closed_dates: {
          ...state.closed_dates,
          variable: [
            ...state.closed_dates.variable,
            moment(action.result.closed_date),
          ],
        },
        no_delivery_dates: {
          ...state.no_delivery_dates,
          variable: [
            ...state.no_delivery_dates.variable,
            moment(action.result.no_delivery_date),
          ],
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${DELETE_SHOP_CLOSED_DATE}_SUCCESS`:
      const closed_date = moment(action.result.closed_date);
      const no_delivery_date = moment(action.result.no_delivery_date);
      return {
        ...state,
        closed_dates: {
          ...state.closed_dates,
          variable: state.closed_dates.variable.filter(
            (date) => !date.isSame(closed_date),
          ),
        },
        no_delivery_dates: {
          ...state.no_delivery_dates,
          variable: state.no_delivery_dates.variable.filter(
            (date) => !date.isSame(no_delivery_date),
          ),
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LIST_SHOP_CLOSED_DATES}_SUCCESS`:
      const { closed_dates, no_delivery_dates } = action.result;
      return {
        ...state,
        closed_dates: {
          fixed: closed_dates.fixed.map((date) => moment(date)),
          variable: closed_dates.variable.map((date) => moment(date)),
        },
        no_delivery_dates: {
          fixed: no_delivery_dates.fixed.map((date) => moment(date)),
          variable: no_delivery_dates.variable.map((date) => moment(date)),
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${ADD_SHOP_CLOSED_DATE}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${LIST_SHOP_CLOSED_DATES}_FAIL`:
      return {
        ...state,
        closed_dates: {
          fixed: [],
          variable: [],
        },
        no_delivery_dates: {
          fixed: [],
          variable: [],
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
