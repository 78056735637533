/**
 * Get shop actions
 * @module actions/shop/shop
 */

import {
  ADD_SHOP_CLOSED_DATE,
  DELETE_SHOP_CLOSED_DATE,
  LIST_SHOP_CLOSED_DATES,
} from '../../constants/ActionTypes';

/**
 * Add closed dates function
 * @function addClosedDate
 * @param {string} url Content url
 * @param {moment} date to add
 * @returns {Object} Add closed date action
 */
export function addClosedDate(url, date) {
  return {
    type: ADD_SHOP_CLOSED_DATE,
    request: {
      op: 'post',
      path: `${url}/@closed_calendar`,
      data: { date: date.format('YYYY-MM-DD') },
    },
  };
}

/**
 * Delete closed date
 * @function deleteClosedDate
 * @param {string} url Content url
 * @param {moment} date to delete
 * @returns {Object} Delete closed date action
 */
export function deleteClosedDate(url, date) {
  return {
    type: DELETE_SHOP_CLOSED_DATE,
    request: {
      op: 'del',
      path: `${url}/@closed_calendar`,
      data: { date: date.format('YYYY-MM-DD') },
    },
  };
}

/**
 * List closed dates
 * @function listClosedDates
 * @returns {Object} List closed dates action
 */
export function listClosedDates(url, monthsQty) {
  return {
    type: LIST_SHOP_CLOSED_DATES,
    request: {
      op: 'get',
      path: `${url}/@closed_calendar?months_qty=${monthsQty}`,
    },
  };
}
