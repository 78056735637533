export function localeParseFloatBak(s, locale) {
  // Get the thousands and decimal separator characters used in the locale.
  let [
    ,
    ,
    thousandsSeparator,
    ,
    ,
    ,
    decimalSeparator,
  ] = (11111.1).toLocaleString(locale);
  // Remove thousand separators, and put a point where the decimal separator occurs
  s = Array.from(s, (c) =>
    c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c,
  ).join('');
  // Now it can be parsed
  return parseFloat(s);
}

export function localeParseFloat(s, locale) {
  const separators = Array.from(s).reduce(
    (previousValue, currentValue) =>
      '.,'.includes(currentValue)
        ? previousValue.concat(currentValue)
        : previousValue,
    [],
  );
  const decimalSeparator = separators.slice(-1);
  if (decimalSeparator[0] === ',') {
    return parseFloat(s.replace(/[^0-9,]/i, '').replace(/,/i, '.'));
  }
  return parseFloat(s);
}
