/**
 * ArticleContainerView view component.
 * @module components/theme/View/ArticleContainerView
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Dimmer,
  Image,
  Input,
  List,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';
import { Pagination, Toast } from '@plone/volto/components';
import { searchContent, updateContent } from '@plone/volto/actions';
import './ArticleContainerView.css';
import { GroupOptionPricesWidget } from '../..';
import {
  activateArticle,
  deactivateArticle,
  disableUntilNextDayArticle,
} from '../../../actions/shop/articles';
import { localeParseFloat } from '../../../helpers';
import { isEqual } from 'lodash';

const messages = defineMessages({
  activateDeactivateIndefinite: {
    id: 'Enable Disable indefinitely',
    defaultMessage: 'Disable / Enable indefinitely',
  },
  activateProductImmediately: {
    id: 'Enable product immediately',
    defaultMessage: 'Enable product immediately',
  },
  deactivateProductImmediately: {
    id: 'Disable product',
    defaultMessage: 'Disable product',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  priceUpdated: {
    id: 'Price updated',
    defaultMessage: 'Price updated',
  },
});
/**
 * ArticleContainerView view component class.
 * @function ArticleContainerView
 * @params {object} content Content object.
 * @return {string} Markup of the component .
 */
const ArticleContainerView = (props) => {
  const {
    data = {},
    content,
    location: { pathname },
  } = props;

  const intl = useIntl();
  const loading = useSelector(
    (state) => state.search.subrequests.articlesList?.loading,
  );
  const articlesListResult = useSelector(
    (state) => {
      return state.search.subrequests.articlesList;
    }
  );

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    console.log('setArticles', articlesListResult);
    setArticles(articlesListResult);
  }, [articlesListResult]);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    currentPage: 0,
    pageSize: 15,
    showConfirmToggleItem: false,
    toggleItemId: null,
    toggleItemAction: null,
    filter: '',
    reviewState: null,
  });

  function onChangePage(event, { value }) {
    setState({
      ...state,
      currentPage: value,
    });
  }

  function onChangePageSize(event, { value }) {
    setState({
      ...state,
      pageSize: value,
      currentPage: 0,
    });
  }

  function fetchArticles(fullobjects, subquery) {
    console.log('fetch articles');
    let query = {
      portal_type: ['apanymantel.cart.shopping.Article'],
      'path.depth': 1, // only first level
      fullobjects,
      b_size: state.pageSize,
      b_start: state.pageSize * state.currentPage,
      ...(state.filter && { SearchableText: `${state.filter}*` }),
    };
    if (state.selectedCategory) {
      query.category = state.selectedCategory;
    }
    if (state.reviewState) {
      query.review_state = state.reviewState;
    }

    dispatch(searchContent(pathname, query, subquery));
  }

  useEffect(() => {
    fetchArticles(true, 'articlesList');
  }, [state.pageSize, state.currentPage, dispatch]);

  // useEffect(() => {
  //   fetchArticles(true, 'articles');
  // }, [state.pageSize, state.currentPage]);

  /**
   * Confirm activate
   * @function confirmActivate
   * @param {object} event object
   * @param {object} data object
   * @param {string} item id
   */
  function confirmActivate(event, data, itemId) {
    setState({
      ...state,
      showConfirmToggleItem: true,
      toggleItemId: itemId,
      toggleItemAction: data.checked ? 'activate' : 'deactivate',
    });
  }

  function itemExpired(item) {
    const now = moment();
    return item.expires && now.isAfter(moment.utc(item.expires));
  }

  function itemUpcoming(item) {
    const now = moment();
    return (
      item.effective &&
      !item.disable_until_next_day &&
      now.isBefore(moment.utc(item.effective))
    );
  }

  /**
   * Toggle Item Cancel
   * @function toggleItemCancel
   * @return {undefined}
   */
  function toggleItemCancel() {
    setState({
      ...state,
      showConfirmToggleItem: false,
      showConfirmToggleUntilTomorrowItem: false,
      toggleItemId: null,
      toggleItemAction: null,
    });
  }

  /**
   * Toggle Item
   * @function toggleItemOK
   * @return {undefined}
   */
  function toggleItemOK() {
    if (state.toggleItemAction === 'deactivate') {
      dispatch(deactivateArticle(flattenToAppURL(state.toggleItemId)));
    } else {
      dispatch(activateArticle(flattenToAppURL(state.toggleItemId)));
    }
    setState({
      ...state,
      showConfirmToggleItem: false,
      toggleItemId: null,
      toggleItemAction: null,
    });
  }

  function changePrice(
    id,
    value,
    oldValue,
    item,
  ) {
    console.log('changePrice', value);
    const options = {
      items: item.options.items.map((groupOption) =>
        groupOption['@id'] === id ? value : groupOption,
      ),
    };
    if (!isEqual(options, item.options)) {
      dispatch(updateContent(flattenToAppURL(item['@id']), { options }))
        .then((resp) => {
          toast.success(
            <Toast
              success
              title={intl.formatMessage(messages.success)}
              content={intl.formatMessage(messages.priceUpdated)}
            />,
          );
          setArticles({
            ...articles,
            items: articles.items.map((item2) => ({
              ...item2,
              options: item2['@id'] === item['@id'] ? options : item2.options,
            })),
          });
          console.log('options', options, 'itemOptions', item.options, 'articles', articles);
          //fetchArticles(true, 'articlesList');
        })
        .catch((error) => {
          console.log('error', error);
          const shadowedError = JSON.parse(error.response.text);
          toast.error(
            <Toast
              error
              title={shadowedError.type}
              content={shadowedError.message}
            />,
            { toastId: 'updateFailed' },
          );
        });
    }
  }

  return (
    <div id="page-contents">
      <Confirm
        open={state.showConfirmToggleItem}
        header={intl.formatMessage(
          state.toggleItemAction === 'deactivate'
            ? messages.deactivateProductImmediately
            : messages.activateProductImmediately,
        )}
        content={
          <div className="content">
            <FormattedMessage id="areYouSure" defaultMessage="Are you sure?" />
          </div>
        }
        onCancel={toggleItemCancel}
        onConfirm={toggleItemOK}
        size="tiny"
      />
      <Dimmer.Dimmable as="div" blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader indeterminate size="massive">
            <FormattedMessage id="Loading" defaultMessage="Loading" />
          </Loader>
        </Dimmer>
        <Container id="content-core">
          {articles?.items &&
            articles.items.map((item) => (
              <Segment key={item['@id']} className="items-wrapper">
                <div className="item-name">
                  <h4>{item.title}</h4>
                </div>
                <div className="item-image">
                  {item.image && (
                    <Image
                      src={flattenToAppURL(item.image.scales.thumb.download)}
                      size="small"
                    />
                  )}
                </div>
                {item.options &&
                  item.options.items.map((groupOption) => (
                    <GroupOptionPricesWidget
                      key={groupOption['@id']}
                      id={groupOption['@id']}
                      groupOption={groupOption}
                      onBlur={(id, value) =>
                        changePrice(id, value, groupOption, item)
                      }
                    />
                  ))}
              </Segment>
            ))}
        </Container>
        {articlesListResult?.batching && (
          <div className="contents-pagination">
            <Pagination
              current={state.currentPage}
              total={Math.ceil(articlesListResult.total / state.pageSize)}
              pageSize={state.pageSize}
              pageSizes={[15, 30, 50]}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </div>
        )}
      </Dimmer.Dimmable>
    </div>
  );
};

export default ArticleContainerView;
