/**
 * Shop reducer
 * @module reducers/shop/activate_article
 */

import {
  PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE,
  POST_ACTIVATE_ARTICLE,
  POST_DEACTIVATE_ARTICLE,
} from '../../constants/ActionTypes';

const initialState = {
  patch: {
    loaded: false,
    loading: false,
    error: null,
  },
  post: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Shop reduceer
 * @function activate_article
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 * @returns {Object} New state
 */
export default function activate_article(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_ACTIVATE_ARTICLE}_PENDING`:
    case `${POST_DEACTIVATE_ARTICLE}_PENDING`:
    case `${PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${POST_ACTIVATE_ARTICLE}_SUCCESS`:
    case `${POST_DEACTIVATE_ARTICLE}_SUCCESS`:
    case `${PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${POST_ACTIVATE_ARTICLE}_FAIL`:
    case `${POST_DEACTIVATE_ARTICLE}_FAIL`:
    case `${PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
