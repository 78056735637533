/**
 * My Shop reducer
 * @module reducers/shop/my_shop
 */

import { GET_MY_SHOPS } from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  my_shops: null,
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * My Shop reduceer
 * @function my_shop
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 * @returns {Object} New state
 */
export default function my_shop(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_MY_SHOPS}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_MY_SHOPS}_SUCCESS`:
      return {
        ...state,
        my_shops: action.result.map((shop) => ({
          ...shop,
          '@id': flattenToAppURL(shop['@id']),
        })),
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_MY_SHOPS}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
