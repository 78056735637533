/**
 * PersonalTools container.
 * @module components/manage/Toolbar/PersonalTools
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import cx from 'classnames';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { getUser, searchContent } from '@plone/volto/actions';
import { userHasRoles } from '@plone/volto/helpers';

import logoutSVG from '@plone/volto/icons/log-out.svg';
import rightArrowSVG from '@plone/volto/icons/right-key.svg';

import backSVG from '@plone/volto/icons/back.svg';
import cameraSVG from '@plone/volto/icons/camera.svg';

import { getMyShops } from '../../../../actions';

const messages = defineMessages({
  myShops: {
    id: 'My Shops',
    defaultMessage: 'My Shops',
  },
  preferences: {
    id: 'Preferences',
    defaultMessage: 'Preferences',
  },
  profile: {
    id: 'Profile',
    defaultMessage: 'Profile',
  },
  userAvatar: {
    id: 'user avatar',
    defaultMessage: 'user avatar',
  },
});

/**
 * Toolbar container class.
 * @function PersonalTools
 * @param {Object}
 * @returns {string} Markup of the component
 */
const PersonalTools = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const hasMyShops = useSelector(
    // (state) => (state.search.subrequests.myshops?.items || []).length > 0,
    (state) => (state.my_shops.my_shops || []).length > 0,
  );

  // useEffect(() => {
  //   if (userId !== '') {
  //     dispatch(getUser(userId));
  //     dispatch(getMyShops(userId));
  //     //fetchShopManagersShop();
  //   }
  // }, [userId, dispatch]);

  // useEffect(() => {
  //   if (hasMyShops) {
  //     history.push('/myshops');
  //   }
  // }, [hasMyShops, history]);

  // function fetchShopManagersShop() {
  //   dispatch(
  //     searchContent(
  //       '/',
  //       {
  //         portal_type: ['apanymantel.cart.shopping.Shop'],
  //         shop_managers: userId,
  //         fullobjects: false,
  //       },
  //       'myshops',
  //     ),
  //   );
  // }

  const push = (selector) => {
    // this.setState(() => ({
    //   pushed: true,
    // }));
    props.loadComponent(selector);
  };

  const pull = () => {
    props.unloadComponent();
  };

  return (
    <div
      className={cx('personal-tools pastanaga-menu', {
        'has-inner-actions': props.hasActions,
      })}
      style={{
        flex: props.theToolbar.current
          ? `0 0 ${props.theToolbar.current.getBoundingClientRect().width}px`
          : null,
      }}
    >
      <header className="header">
        <button className="back" onClick={pull}>
          <Icon name={backSVG} size="30px" />
        </button>
        <div className="vertical divider" />
        <h2>{user.fullname ? user.fullname : user.username}</h2>
        <Link id="toolbar-logout" to="/logout">
          <Icon className="logout" name={logoutSVG} size="30px" />
        </Link>
      </header>
      <div className={cx('avatar', { default: !user.portrait })}>
        {user.portrait ? (
          <img
            src={user.portrait}
            alt={intl.formatMessage(messages.userAvatar)}
          />
        ) : (
          <Icon name={cameraSVG} size="96px" />
        )}
      </div>
      {/* <Stats /> Maybe we can find a good fit in the future for this visual element */}
      <div className="pastanaga-menu-list">
        {/* This (probably also) should be a Component by itself*/}
        <ul>
          <li>
            <button
              aria-label={intl.formatMessage(messages.profile)}
              onClick={() => push('profile')}
            >
              <FormattedMessage id="Profile" defaultMessage="Profile" />
              <Icon name={rightArrowSVG} size="24px" />
            </button>
          </li>
          <li>
            <button
              aria-label={intl.formatMessage(messages.preferences)}
              onClick={() => push('preferences')}
            >
              <FormattedMessage id="Preferences" defaultMessage="Preferences" />
              <Icon name={rightArrowSVG} size="24px" />
            </button>
          </li>

          {hasMyShops && (
            <li>
              <Link to="/myshops">
                <FormattedMessage id="My Shops" defaultMessage="My Shops" />
                <Icon name={rightArrowSVG} size="24px" />
              </Link>
            </li>
          )}

          {userHasRoles(user, ['Site Administrator', 'Manager']) && (
            <li>
              <Link to="/controlpanel">
                <FormattedMessage id="Site Setup" defaultMessage="Site Setup" />
                <Icon name={rightArrowSVG} size="24px" />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default PersonalTools;
