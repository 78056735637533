import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { isNull } from 'lodash';
import { getMyShops } from '../../actions';

const RedirectToMyShops = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = location;
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const myShops = useSelector((state) => state.my_shops.my_shops);

  useEffect(() => {
    if (userId && isNull(myShops)) {
      dispatch(getMyShops(userId));
    }
  }, [dispatch, userId, myShops]);

  useEffect(() => {
    if (myShops && pathname === '/') {
      history.push('/myshops');
    }
  }, [pathname, history, myShops]);
  return '';
};

export default RedirectToMyShops;
