/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import eeacmsvoltoReactTableWidget from '@eeacms/volto-react-table-widget';

const addonsInfo = [{"name":"@eeacms/volto-react-table-widget","version":"0.1.2","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/plone-frontend/node_modules/@eeacms/volto-react-table-widget/src","packageJson":"/build/plone-frontend/node_modules/@eeacms/volto-react-table-widget/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoReactTableWidget];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
