import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import { cloneDeep } from 'lodash';

const messages = defineMessages({
  add: {
    id: 'Add option',
    defaultMessage: 'Add option',
  },
  addTimetable: {
    id: 'Add timetable',
    defaultMessage: 'Add timetable',
  },
  day: {
    id: 'Day',
    defaultMessage: 'Day',
  },
  fromDate: {
    id: 'From Date',
    defaultMessage: 'From Date',
  },
  fromTime: {
    id: 'From Time',
    defaultMessage: 'From Time',
  },
  repeatEveryYear: {
    id: 'Repeat every year',
    defaultMessage: 'Repeat every year',
  },
  toDate: {
    id: 'To Date',
    defaultMessage: 'To Date',
  },
  toTime: {
    id: 'To Time',
    defaultMessage: 'To Time',
  },
});

const timetableSchemaExtender = (schema, data, intl) => {
  const mutated = cloneDeep(schema);
  console.log('ttle should be', data.fromDate);
  mutated.title = `${data.fromDate}-${data.toDate}, repeat ${data.repeatEveryYear}, ${data.day}, ${data.fromTime}-${data.toTime}`;
  return mutated;
};

const timetableSchema = (intl) => ({
  title: 'Timetable',
  addMessage: intl.formatMessage(messages.addTimetable),
  properties: {
    fromDate: {
      title: intl.formatMessage(messages.fromDate),
      type: 'date',
    },
    toDate: {
      title: intl.formatMessage(messages.toDate),
      type: 'date',
    },
    repeatEveryYear: {
      title: intl.formatMessage(messages.repeatEveryYear),
      type: 'boolean',
    },
    day: {
      title: intl.formatMessage(messages.day),
      type: 'string',
    },
    fromTime: {
      title: intl.formatMessage(messages.fromTime),
      type: 'string',
    },
    toTime: {
      title: intl.formatMessage(messages.toTime),
      type: 'string',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'fromDate',
        'toDate',
        'repeatEveryYear',
        'day',
        'fromTime',
        'toTime',
      ],
    },
  ],
  required: [],
});

const TimetableWidget = (props) => {
  const moment = props.moment.default;
  const intl = useIntl();

  console.log('props', props);
  // props.value?.items || props.default?.items || []
  //const value = [];
  props.value.map((v, idx) => {
    console.log('from_date', v.from_date);
    console.log('moment', moment(v.from_date));
  });
  const value = props.value.map((v, idx) => ({
    '@id': idx,
    fromDate: v.from_date,
    toDate: v.to_date,
    repeatEveryYear: v.repeat_every_year,
    day: v.day,
    fromTime: v.from_time,
    toTime: v.to_time,
  }));

  const onChange = (id, value) => {
    // props.onChange(id, value.map(v => ({
    //   from_date: v.fromDate,
    //   to_date: v.toDate,
    //   repeat_every_year: v.repeatEveryYear,
    //   day: v.day,
    //   from_time: v.fromTime,
    //   to_time: v.toTime,
    // })));
    console.log('onChange', id, value);
  };

  //    onChange={(id, value) => props.onChange(id, { items: value })}
  return (
    <ObjectListWidget
      schema={timetableSchema(intl)}
      schemaExtender={timetableSchemaExtender}
      {...props}
      value={value}
      onChange={(id, value) => onChange(id, { items: value })}
    />
  );
};

export default injectLazyLibs(['moment'])(TimetableWidget);
