/**
 * GroupOptionPricesWidget component.
 * @module components/manage/Widgets/GroupOptionPricesWidget
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';
import { injectIntl } from 'react-intl';
import { localeParseFloat } from '../../../helpers';

function formatValue(value) {
  return value
    ? parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';
}

function validateNumberField(myNumber) {
  const numberRegEx = /-?\d*[.,]?\d{1,2}/;
  return numberRegEx.test(String(myNumber).toLowerCase());
}

/**
 * GroupOptionPricesWidget component
 * */
const GroupOptionPricesWidget = (props) => {
  const {
    id,
    groupOption,
    value,
    onChange,
    onBlur,
    onClick,
    isDisabled,
    placeholder,
  } = props;

  const [state, setState] = useState(() => ({
    groupOption,
    isValid: true,
  }));

  const [formattedValues, setFormattedValues] = useState(() =>
    groupOption.options.items.reduce(
      (total, option) => ({
        ...total,
        [option['@id']]: formatValue(option.price),
      }),
      {},
    ),
  );

  function onChange2(optionId, price) {
    const isValid = !price || validateNumberField(price);
    setFormattedValues({
      ...formattedValues,
      [optionId]: price,
    });
    setState({
      ...state,
      isValid,
    });
    if (isValid && onChange) {
      onChange(id, value);
    }
  }

  function onBlur2(optionId, price) {
    const priceFloat = price ? localeParseFloat(price) : undefined;
    const newGroupOption = {
      ...groupOption,
      options: {
        items: groupOption.options.items.map((option) => ({
          ...option,
          price: option['@id'] === optionId ? priceFloat : option.price,
        })),
      },
    };

    setFormattedValues({
      ...formattedValues,
      [optionId]: formatValue(priceFloat),
    });

    setState({
      ...state,
      groupOption: newGroupOption,
    });
    if (state.isValid && onBlur) {
      onBlur(id, newGroupOption);
    }
  }

  return (
    <div className="prices-table">
      <div className="group-option-name"> {state.groupOption.name}: </div>
      <div className="prices-wrapper">
        {state.groupOption.options?.items &&
          state.groupOption.options.items.map((option, idx) => (
            <div key={option['@id']} className="price-wrapper">
              <div className="option-name"> {option.name} </div>
              <div className="option-price">
                <Input
                  id={`${state.groupOption['@id']}-${option['@id']}`}
                  type="text"
                  value={formattedValues[option['@id']]}
                  onChange={({ target }) =>
                    onChange2(option['@id'], target.value)
                  }
                  onBlur={({ target }) =>
                    onBlur2(
                      option['@id'],
                      target.value,
                    )
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
GroupOptionPricesWidget.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  groupOption: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  wrapped: PropTypes.bool,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
GroupOptionPricesWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
};

export default injectIntl(GroupOptionPricesWidget);
