/**
 * ClosedDates component
 * @module components/theme/ClosedDates/ClosedDates
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { getVocabulary } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate } from '@plone/volto/components';

const messages = defineMessages({
  day: {
    id: 'day',
    defaultMessage: 'Day',
  },
  fromDate: {
    id: 'fromDate',
    defaultMessage: 'From Date',
  },
  fromTime: {
    id: 'fromTime',
    defaultMessage: 'From Time',
  },
  repeatEveryYear: {
    id: 'repeatEveryYear',
    defaultMessage: 'Repeat every Year',
  },
  toDate: {
    id: 'toDate',
    defaultMessage: 'To Date',
  },
  toTime: {
    id: 'toTime',
    defaultMessage: 'To Time',
  },
});

const DAYS_VOCABULARY = 'apanymantel.cart.shopping.days';
/**
 * Component to display a closed dates calendar.
 * @function Field
 * @param {Array} closedDates ClosedDates
 * @returns {string} Markup of the component.
 */
const ClosedDates = ({ closedDates }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: DAYS_VOCABULARY }));
  }, [dispatch]);

  const daysVocab = useSelector((state) => state.vocabularies[DAYS_VOCABULARY]);

  const getDay = (day) => {
    const dayTerms = daysVocab?.items?.filter(
      (dayTerm) => dayTerm.value === day,
    );

    if (dayTerms && dayTerms.length > 0) {
      return dayTerms[0].label;
    }
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="fromDate" defaultMessage="From Date" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="toDate" defaultMessage="To Date" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage
              id="repeatEveryYear"
              defaultMessage="Repeat every Year"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="day" defaultMessage="Day" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {closedDates.map((row, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>
              {row.from_date && <FormattedDate date={row.from_date} />}
            </Table.Cell>
            <Table.Cell>
              {row.to_date && <FormattedDate date={row.to_date} />}
            </Table.Cell>
            <Table.Cell>{row.repeat_every_year}</Table.Cell>
            <Table.Cell>{getDay(row.day)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
ClosedDates.propTypes = {
  /**
   * ClosedDates
   */
  closedDates: PropTypes.arrayOf(
    PropTypes.shape({
      from_date: PropTypes.string.isRequired,
      to_date: PropTypes.string.isRequired,
      // from_date: PropTypes.instanceOf(Date).isRequired,
      // to_date: PropTypes.instanceOf(Date).isRequired,
      repeat_every_year: PropTypes.bool,
      day: PropTypes.oneOf([
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]),
    }),
  ),
};

/**
 * Default props.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ClosedDates.defaultProps = {
  closedDates: [],
};

export default injectIntl(ClosedDates);
