/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import { ArticleContainerView, ShopView } from '../components';
import { widgetMapping } from './Widgets';
import { nonContentRoutes } from './NonContentRoutes';
// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { redirectShopstaffToMyShops } from '../middleware';

import { RedirectToMyShops } from '../components';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  const redirectToMyShops = (middlewares) => [
    ...middlewares,
    redirectShopstaffToMyShops,
  ];

  config.settings = {
    ...config.settings,
    //apiPath: 'http://localhost:5080/www_apanymantel_com',
    devProxyToApiPath: 'http://localhost:5080/www_apanymantel_com',
    isMultilingual: false,
    supportedLanguages: ['en', 'es', 'ca', 'eu', 'ga'],
    defaultLanguage: 'es',
    nonContentRoutes: [...config.settings.nonContentRoutes, nonContentRoutes],
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: RedirectToMyShops,
      },
    ],
  };
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'apanymantel.cart.shopping.Shop': ShopView,
      'apanymantel.cart.shopping.ArticleContainer': ArticleContainerView,
    },
    layoutViews: {
      ...config.views.layoutViews,
    },
  };
  config.widgets = {
    ...config.widgets,
    widget: {
      ...config.widgets.widget,
      ...widgetMapping.widget,
    },
  };
  return config;
}
