/**
 * Get shop actions
 * @module actions/shop/categories
 */

import {
  GET_ARTICLE_CATEGORIES,
} from '../../constants/ActionTypes';

/**
 * Get article categories
 * @function getArticleCategories
 * @param {string} url Content url
 * @returns {Object} Get article categories action
 */
export function getArticleCategories(url) {
  return {
    type: GET_ARTICLE_CATEGORIES,
    request: {
      op: 'get',
      path: `${url}/@categories`,
    },
  };
}
