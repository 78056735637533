/**
 * Get my shops actions
 * @module actions/shop/myshops
 */

import { GET_MY_SHOPS } from '../../constants/ActionTypes';

/**
 * Get my shops
 * @function getMyShops
 * @returns {array} Get my shops action
 */
export function getMyShops(userId) {
  return {
    type: GET_MY_SHOPS,
    request: {
      op: 'get',
      path: `/@my_shops?userid=${userId}`,
    },
  };
}
