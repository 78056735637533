import React from 'react';
import './MonthCalendar.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getBaseUrl } from '@plone/volto/helpers';
import {
  addClosedDate,
  deleteClosedDate,
} from '../../../actions/shop/closed_dates';

function weeksInMonth(date = null) {
  const end = moment(date).endOf('month');

  const startDay = moment(date).startOf('month').weekday();

  const endDay = end.weekday();
  const daysInMonth = end.date();
  return (startDay + daysInMonth + (6 - endDay)) / 7;
}

const MonthCalendar = ({ month, pathname }) => {
  const intl = useIntl();
  moment.locale(intl.locale);

  const dispatch = useDispatch();

  const today = moment().startOf('day');
  const daysInMonth = month.daysInMonth();
  const monthWeeks = weeksInMonth(month);
  //  month.endOf('month').week() - month.startOf('month').week() + 1;
  const monthMatrix = Array.from({ length: monthWeeks }, (item, w) =>
    Array.from({ length: 7 }, (item, wd) => {
      const currentDay = w * 7 + wd - month.weekday() + 1;
      return currentDay >= 1 && currentDay <= daysInMonth
        ? moment([month.year(), month.month(), currentDay])
        : null;
    }),
  );
  const dayNames = moment.weekdaysMin(true);

  const closedDates = useSelector((state) => state.closed_dates.closed_dates);
  const noDeliveryDates = useSelector(
    (state) => state.closed_dates.no_delivery_dates,
  );

  const isClosedFixed = (date) => {
    return closedDates && closedDates.fixed.some((e) => e.isSame(date));
  };
  const isClosedVariable = (date) => {
    return closedDates && closedDates.variable.some((e) => e.isSame(date));
  };
  const isNoDeliveryFixed = (date) => {
    return noDeliveryDates && noDeliveryDates.fixed.some((e) => e.isSame(date));
  };
  const isNoDeliveryVariable = (date) => {
    return (
      noDeliveryDates && noDeliveryDates.variable.some((e) => e.isSame(date))
    );
  };

  const toggle = (date) => {
    if (isClosedVariable(date) || isNoDeliveryVariable(date)) {
      dispatch(deleteClosedDate(getBaseUrl(pathname), date));
    } else {
      dispatch(addClosedDate(getBaseUrl(pathname), date));
    }
  };

  const isSameOrAfterToday = (date) => {
    return date.isSameOrAfter(today);
  };

  return (
    <div>
      <h3>{month.format('MMMM YYYY')}</h3>
      <table className="ui celled table unstackable month-calendar">
        <thead>
          <tr>
            {dayNames.map((day, idx) => (
              <th key={idx}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {monthMatrix.map((week, idx) => (
            <tr key={idx}>
              {week.map((day, idx) => (
                <td
                  className={[
                    day &&
                    !isClosedFixed(day) &&
                    !isNoDeliveryFixed(day) &&
                    isSameOrAfterToday(day)
                      ? 'selectable'
                      : '',
                    isClosedFixed(day) ? 'closed-fixed' : '',
                    isClosedVariable(day) ? 'closed-variable' : '',
                    isNoDeliveryFixed(day) ? 'no-delivery-fixed' : '',
                    isNoDeliveryVariable(day) ? 'no-delivery-variable' : '',
                  ].join(' ')}
                  key={idx}
                >
                  {day &&
                    !isClosedFixed(day) &&
                    !isNoDeliveryFixed(day) &&
                    isSameOrAfterToday(day) && (
                      <a href="javascript:;" role="button" tabIndex={0} onClick={() => toggle(day)}>
                        {day.date()}
                      </a>
                    )}
                  {day &&
                    (isClosedFixed(day) ||
                      isNoDeliveryFixed(day) ||
                      !isSameOrAfterToday(day)) && <span>{day.date()}</span>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

MonthCalendar.propTypes = {
  pathname: PropTypes.string.isRequired,
  month: PropTypes.instanceOf(moment).isRequired,
};

export default MonthCalendar;
